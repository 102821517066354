import * as crypto from 'crypto';

import { useCallback, useState } from 'react';

import { generatePowChallenge } from 'apiClient';
import { PoWChallenge, PoW } from 'types/powChallenge';

export const useProofOfWork = (): PoW => {
  const [challenge, setChallenge] = useState<PoWChallenge | null>({
    isEnabled: true,
  });

  const fetchChallenge = useCallback(async () => {
    try {
      const powChallenge = await generatePowChallenge();

      if (powChallenge.success === true && powChallenge.nonce) {
        const solution = getProofOfWorkSolution(
          powChallenge.complexity,
          powChallenge.nonce
        );
        setChallenge({
          nonce: powChallenge.nonce,
          isEnabled: true,
          isUsed: false,
          solution,
        });
      } else {
        setChallenge({ isEnabled: false });
      }
    } catch (error) {
      setChallenge({ isEnabled: false });
    }
  }, []);

  return { challenge, fetchChallenge };
};

const getProofOfWorkSolution = (complexity: number, nonce: string): number => {
  const isSolved = false;
  const expectedSuffix = String('0').repeat(complexity);
  for (let solution = 0; !isSolved; solution++) {
    const hash = crypto
      .createHash('sha256')
      .update(`${nonce}${solution}`)
      .digest('hex');
    if (hash.endsWith(expectedSuffix)) {
      return solution;
    }
  }

  return 0;
};
