import Cookie, { CookieAttributes } from 'js-cookie';

import { PROFILE_ACCESS_TOKEN_KEY, USER_ACCESS_TOKEN_KEY } from 'api/utils';

const getDomain = (domain?: string): string | undefined => {
  const isPreview =
    document?.location?.hostname?.endsWith('.vercel.app') &&
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';
  if (isPreview) {
    return undefined;
  }

  return domain ?? process.env.NEXT_PUBLIC_DOMAIN;
};

const setCookie = (key: string, value: string, options?: CookieAttributes) => {
  const domain = getDomain(options?.domain);

  Cookie.set(key, value, {
    sameSite: 'lax',
    secure: !(domain === 'localhost'), // Safari does not save secure cookies on localhost
    expires: 30,
    domain,
    path: '/',
    ...options,
  });
};

export const setAccessCookies = (
  accessToken: string,
  options?: CookieAttributes
) => {
  setCookie(USER_ACCESS_TOKEN_KEY, accessToken, options);
  setCookie(PROFILE_ACCESS_TOKEN_KEY, accessToken, options);
};
